<template>
  <div>
    <div class="modal" data-backdrop="static" id="request-transfer-modal">
      <div class="modal-dialog rounded" role="document">
        <div class="modal-content">
          <div v-if="loader" class="d-flex justify-content-center p-5">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

          <div
            v-if="!loader && showSuccessMessage"
            class="d-flex justify-content-center p-5"
          >
            <div class="text-center">
              <p class="mb-1">
                <i class="bi bi-patch-check fs-3 text-success"></i>
              </p>
              <h5>Request has been submitted.</h5>
              <p class="text-muted">
                Kindly wait for the HEI's confirmation and the CHED Regional
                Office's approval.
              </p>
              <button class="btn btn-link" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>

          <form
            v-if="!loader && !showSuccessMessage"
            @submit.prevent="handleSubmit"
          >
            <div class="modal-header">
              <h5 class="modal-title">Request Transfer</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="table-responsive mb-3">
                <table class="table text-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Student ID</th>
                      <th scope="col">Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="student in selected" :key="student.id">
                      <th scope="row">{{ student.id }}</th>
                      <td>
                        {{ student.last_name }}. {{ student.first_name }}
                        {{ student.middle_name }} {{ student.extension_name }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mb-3">
                <label for="" class="form-label">Transfer to</label>
                <select
                  class="form-select col-auto"
                  v-model="form.destination_hei_code"
                  required
                >
                  <option selected disabled hidden value="">Choose</option>
                  <option v-for="hei in heis" :key="hei.code" :value="hei.code">
                    {{ hei.name }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label for="" class="form-label">Remarks</label>
                <textarea
                  class="form-control"
                  v-model="form.remarks"
                  required
                  rows="5"
                >
                </textarea>
              </div>

              <div v-if="errorMessage" class="alert alert-danger" role="alert">
                <div>{{ errorMessage }}</div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-link"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestTransferModal",
  props: {
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: new Form({
        selectedStudents: [],
        destination_hei_code: "",
      }),
      heis: [],
      loader: false,
      showSuccessMessage: false,
    };
  },
  methods: {
    open() {
      this.form.reset();
      this.showSuccessMessage = false;
      this.errorMessage = "";

      var modal = new bootstrap.Modal(
        document.getElementById("request-transfer-modal")
      );
      modal.toggle();
      this.getHeis();
    },
    getHeis() {
      this.loader = true;
      axios
        .get("api/heis")
        .then((response) => {
          this.heis = response.data;
        })
        .catch((error) => {
          alert("Failed to retrieve HEI list.");
        })
        .finally(() => {
          this.loader = false;
        });
    },
    handleSubmit() {
      this.loader = true;
      this.form.selectedStudents = this.selected;

      axios
        .post("api/nstp/transfer-requests", this.form)
        .then((response) => {
          if (response && response.status === 201) {
            this.$emit("getApplications");
            this.loader = false;
            this.showSuccessMessage = true;
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data.message;
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>
