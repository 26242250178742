<template>
  <div class="">
    <div
      class="d-flex align-items-center justify-content-center"
      style="height: 100vh"
    >
      <div
        class="card shadow-lg"
        style="width: 770px; border-radius: 0.5rem !important"
      >
        <div class="card-body py-0 px-0 my-0">
          <div class="row">
            <div class="col-5">
              <form @submit.prevent="handleLogin">
                <div class="container px-4 py-4">
                  <h4 class="mb-1">NSTP Portal</h4>
                  <span class="form-text">Sign in to your account</span>

                  <div class="mb-3 mt-4">
                    <label for="" class="form-label">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="email"
                      placeholder="Your registered email"
                      required
                    />
                  </div>
                  <div class="mb-4">
                    <label for="" class="form-label">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="password"
                      placeholder="Password"
                      required
                    />
                  </div>
                  <div class="mb-3 form-check">
                    <input type="checkbox" class="form-check-input" disabled />
                    <label class="form-check-label">Remember me</label>
                  </div>
                  <button
                    id="login-button"
                    type="submit"
                    class="btn btn-primary w-100 mt-1"
                  >
                    Login
                  </button>

                  <p class="text-danger my-2" v-show="errorMessage">
                    {{ errorMessage }}
                  </p>

                  <p class="text-muted mb-0 mt-4 small">
                    Commission on Higher Education
                  </p>
                  <p class="text-muted my-0 small">Caraga Region</p>
                </div>
              </form>
            </div>
            <div class="col px-0">
              <img
                src="@/assets/images/nstp.png"
                alt=""
                class=""
                style="border-radius: 0 0.5rem 0.5rem 0 !important"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    handleLogin() {
      let b = document.getElementById("login-button");
      b.innerHTML =
        '<span class="spinner-border spinner-border-sm mr-1"></span> Logging in';
      b.disabled = true;

      axios.get("/sanctum/csrf-cookie").then((response) => {
        // handle login
        // console.log("this is an error", response);
        axios
          .post("/login", {
            email: this.email,
            password: this.password,
          })
          .then((response) => {
            // console.log(response);
            this.$store.commit("setAuthentication", true);
            this.$store.commit("setUser", response.data);
            this.$router.push({ name: "Main" });
          })
          .catch((error) => {
            b.innerHTML = "Login";
            b.disabled = false;
            console.log(error.response.data.message);
            this.errorMessage = error.response.data.message;
          });
      });
    },
  },
  mounted() {},
};
</script>
