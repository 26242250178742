<template>
  <div>
    <div class="card shadow-sm" style="border-radius: 0.5rem !important">
      <div class="card-body">
        <div class="table-responsive">
          <table
            id="hei-list-of-application-table"
            class="table text-nowrap table-hover"
          >
            <thead style="">
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>COMPONENT</th>

                <th>TRANSFER TO</th>
                <th>DATE REQUESTED</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!students.length && !tableLoader">
                <td colspan="13">No pending transfer requests.</td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="13">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr v-for="student in students" :key="student.id">
                <td>{{ student.student_id }}</td>
                <td>
                  {{ student.last_name }}, {{ student.first_name }}
                  {{ student.middle_name }} {{ student.extension_name }}
                </td>
                <td>{{ student.component }}</td>
                <td>{{ student.hei_name }}</td>
                <td>
                  {{ student.created_at | dateTime }}
                </td>

                <td>
                  <span class="badge bg-secondary">{{ student.status }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransferRequests",
  data() {
    return {
      tableLoader: false,
      students: [],
      showModal: false,
    };
  },
  methods: {
    getTransferRequests(loader) {
      this.tableLoader = loader.isEnabled;
      axios
        .get(
          "api/nstp/hei-transfer-requests/" + this.$store.state.user.hei_code
        )
        .then((res) => {
          this.students = res.data;
          this.tableLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
  },
  mounted() {
    this.getTransferRequests({
      isEnabled: true,
    });
  },
};
</script>
