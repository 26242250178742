export function hasEmptyOrNullProperties(object) {
  if (typeof object !== "object") return;

  for (let key in object) {
    if (object[key] == undefined || object[key] == "" || object[key] == null) {
      if (key == "extension_name" || key == "middle_name" || key == "province")
        continue;
      return true;
    }
  }

  return false;
}
