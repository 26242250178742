<template>
  <div>
    <div class="card shadow-sm" style="border-radius: 0.5rem !important">
      <div class="card-body">
        <div class="table-responsive">
          <table
            id="hei-list-of-application-table"
            class="table text-nowrap table-hover"
          >
            <thead style="">
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>COMPONENT</th>
                <th>ACADEMIC YEAR</th>
                <th>PROGRAM</th>
                <th>DATE REQUESTED</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-if="!applications.length && !tableLoader"
              >
                <td colspan="13">Hooray! You have no pending requests.</td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="13">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr
                v-for="app in applications"
                :key="app.id"
                @click.stop="openStudentInformationModal(app)"
              >
                <td>{{ app.id }}</td>
                <td>
                  {{ app.last_name }}, {{ app.first_name }}
                  {{ app.middle_name }} {{ app.extension_name }}
                </td>
                <td>{{ app.component }}</td>
                <td>{{ app.award_year }}</td>
                <td class="text-uppercase">{{ app.program_name }}</td>
                <td>
                  {{ app.updated_at | dateTime }}
                </td>

                <td>
                  <span class="badge bg-secondary">FOR APPROVAL</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <StudentInformationModal
      ref="StudentInformationModal"
      :showSerialNumber="false"
      :components="components"
      :awardYears="awardYears"
      :editable="true"
      :heiPrograms="heiPrograms"
      v-on:getApplications="getApplications({ isEnabled: false })"
    ></StudentInformationModal>
  </div>
</template>

<script>
import StudentInformationModal from "./StudentInformationModal.vue";

export default {
  name: "Applications",
  props: {
    components: {
      required: true,
      type: Array,
    },
    awardYears: {
      required: true,
      type: Array,
    },
    heiPrograms: {
      required: true,
      type: Array,
    },
  },
  components: {
    StudentInformationModal,
  },
  data() {
    return {
      tableLoader: false,
      applications: [],
      showModal: false,
    };
  },
  methods: {
    openStudentInformationModal(student) {
      this.$refs.StudentInformationModal.open(student);
    },
    getApplications(loader) {
      this.tableLoader = loader.isEnabled;
      axios
        .get("api/nstp/hei/applications")
        .then((res) => {
          this.applications = res.data;
          this.tableLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
  },
  mounted() {
    this.getApplications({
      isEnabled: true,
    });
  },
};
</script>

<style scoped>
#hei-list-of-application-table tr {
  cursor: pointer !important;
}
</style>
